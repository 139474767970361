<template>
  <el-main>
    <el-button size="small" style="margin-bottom:16px" type="primary"
      @click="$router.push('/extension/gatewayActivity/addActivity')">+ 添加活动</el-button>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="活动标题：">
        <el-input size="small" v-model="ruleForm.title" placeholder="请输入活动标题"></el-input>
      </el-form-item>
      <el-form-item label="活动分类：">
        <el-select size="small" v-model="ruleForm.cate_id" placeholder="请选择活动分类">
          <el-option v-for="item in cateList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上架时间：">
        <el-date-picker size="small" v-model="ruleForm.upTime" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="活动时间：">
        <el-date-picker size="small" v-model="ruleForm.startTime" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="上线状态：">
        <el-select size="small" v-model="ruleForm.is_up" placeholder="请选择">
          <el-option v-for="item in upStatus" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数据来源：">
        <el-select size="small" v-model="ruleForm.comfrom" placeholder="请选择">
          <el-option v-for="item in comfromStatus" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动城市：">
        <el-cascader size="small" v-model="ruleForm.city_id" :options="allAreaList"
          :props="{ expandTrigger: 'hover', children: '_child', label: 'name', value: 'id' }">
        </el-cascader>
      </el-form-item>
      <el-form-item label="审核状态：">
        <el-select size="small" v-model="ruleForm.status" placeholder="请选择">
          <el-option v-for="item in examineStatus" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="getDataList(1)">搜索</el-button>
        <el-button size="small" type="text" @click="clearSearch">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" style="width: 100%" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column align="center">
        <template #header>
          <div class="rank">
            <span>排序</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template v-slot="{ row }">
          {{ row.sort }}
          <!--<el-input size="small" v-model="row.sort"></el-input>-->
        </template>
      </el-table-column>
      <el-table-column prop="cate_name" label="活动分类" align="center"></el-table-column>
      <el-table-column prop="title" label="活动标题" align="center"></el-table-column>
      <el-table-column label="数据来源" align="center" width="120px">
        <template v-slot="{ row }">
          {{ row.comfrom === 1 ? '后台上传' : '小程序上传' }}
        </template>
      </el-table-column>
      <el-table-column label="上传会员昵称" align="center" width="150px">
        <template v-slot="{ row }">
          {{ row.nick_name || '--' }}
        </template>
      </el-table-column>
      <el-table-column label="会员手机号" align="center" width="200px">
        <template v-slot="{ row }">
          {{ row.mobile || '--' }}
        </template>
      </el-table-column>
      <el-table-column label="活动封面" align="center">
        <template v-slot="{ row }">
          <el-image style="width: 50px; height: 50px" :src="row.image" :preview-src-list="[row.image]"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="上架时间" align="center" width="200px">
        <template v-slot="{ row }">
          {{ row.is_up ? getDateformat(row.up_time) : '--' }}
        </template>
      </el-table-column>
      <el-table-column label="活动时间" align="center" width="200px">
        <template v-slot="{ row }">
          {{ getDateformat(row.start_time) }}至{{ getDateformat(row.end_time) }}
        </template>
      </el-table-column>
      <el-table-column label="活动城市/区县" align="center" width="150px">
        <template v-slot="{ row }">
          {{ row.province_name + row.city_name }}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="活动地址" align="center"></el-table-column>
      <el-table-column label="活动状态" align="center">
        <template v-slot="{ row }">
          <span
            :style="{ color: getActivityStatus(row) === '进行中' ? '#fdc176' : getActivityStatus(row) === '已完成' ? '#1989fa' : '' }">{{
            getActivityStatus(row) }}</span>
        </template>
      </el-table-column>
      <!--<el-table-column label="审核状态" align="center">
        <template v-slot="{ row }">
          <span
            :style="{color:row.status===-1?'#f56c6c':row.status===0?'#e6a23c':'#409eff'}">{{row.status===-1?'已驳回':row.status===0?'未审核':'已通过'}}</span>
        </template>
      </el-table-column>-->
      <el-table-column label="展示/下架" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-switch v-model="row.is_up" :active-value="1" :inactive-value="0" @change="upChange(row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="170px">
        <template v-slot="{ row }">
          <el-button type="text" v-if="!row.is_up" @click="detailsInfo(row)">编辑</el-button>
          <el-button type="text" v-else @click="detailsInfo(row, 1)">查看</el-button>
          <el-button type="text" @click="deleteActivity(row)">删除</el-button>
          <el-button type="text">活动订单</el-button>
          <el-button type="text" @click="$router.push('/extension/customform/datalist?form_id=' + row.form_id)">活动表单
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="dataTotal" :page="ruleForm.page" :pageNum="ruleForm.rows" @updatePageNum="updateData"></Paging>
    <!-- 下架弹框 -->
    <el-dialog title="驳回" :visible.sync="rejectDialogFlag" width="400px" @closed="dealReject(0)">
      <div>
        <p style="margin-bottom:20px">请添加下架原因：</p>
        <el-input v-model="rejectResultTxt" type="textarea" :rows="5"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dealReject(0)">取 消</el-button>
        <el-button type="primary" @click="dealReject(1)">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="驳回原因" :visible.sync="rejectResultFlag" width="400px">
      <div>{{nowEditData.down_reason}}</div>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging.vue';
import { getDateformat } from '@/util/getDate.js'
export default {
  components: {
    Paging
  },
  data () {
    return {
      getDateformat,
      ruleForm: {
        page: 1,
        rows: 10,
        cate_id: null, // 服务三级分类
        title: '',
        upTime: [],
        startTime: [],
        //status: -2,
        comfrom: 0,
        city_id: null,
        is_up: 0,
      },
      dataList: [],
      dataTotal: 0,
      cateList: [],
      allAreaList: [],
      upStatus: [
        { id: 0, name: "全部" },
        { id: 1, name: "上架" },
        { id: -1, name: "下架" },
      ],
      comfromStatus: [
        { id: 0, name: "全部" },
        { id: 1, name: "后台上传" },
        { id: 2, name: "小程序上传" },
      ],
      // 下架 || 显示下架原因
      nowEditData: {},
      rejectDialogFlag: false,
      rejectResultFlag: false,
      rejectResultTxt: '',
      //examineStatus: [
      //  { id: -2, name: "全部" },
      //  { id: 0, name: "未审核" },
      //  { id: 1, name: "通过 " },
      //  { id: -1, name: "拒绝" },
      //]
    }
  },
  created () {
    this.getArea()
    this.getDataList()
  },
  methods: {
    // 获取省市区信息
    getArea () {
      this.$axios
        .get(this.$api.shopDiy.areaList, {
          tree: 1,
          level: 1,
        })
        .then(res => {
          if (res.code == 0) {
            this.allAreaList = res.result.list;
          }
        });
    },
    clearSearch () {
      this.ruleForm = {
        page: 1,
        rows: 10,
        cate_id: null, // 服务三级分类
        title: '',
        upTime: [],
        startTime: [],
        //status: -2,
        comfrom: 0,
        city_id: null,
        is_up: 0,
      }
      this.getDataList()
    },
    updateData (val, status) {
      if (status == 0) {
        this.ruleForm.rows = val;
      } else {
        this.ruleForm.page = val;
      }
      this.getDataList()
    },
    // 获取活动列表数据
    getDataList (style) {
      if (style) this.ruleForm.page = 1
      let obj = {
        page: this.ruleForm.page,
        rows: this.ruleForm.rows,
      }
      if (this.ruleForm.cate_id) obj.cate_id = this.ruleForm.cate_id
      if (this.ruleForm.title) obj.title = this.ruleForm.title
      //if (this.ruleForm.status !== -2) obj.status = this.ruleForm.status
      if (this.ruleForm.is_up) obj.is_up = this.ruleForm.is_up
      if (this.ruleForm.comfrom) obj.comfrom = this.ruleForm.comfrom
      if (this.ruleForm.upTime?.length) {
        obj.start_time = Math.ceil(this.ruleForm.upTime[0].getTime() / 1000)
        obj.end_time = Math.ceil(this.ruleForm.upTime[1].getTime() / 1000)
        obj.end_time = obj.start_time == obj.end_time ? obj.end_time + 60 * 60 * 24 - 1 : obj.end_time
      }
      if (this.ruleForm.startTime?.length) {
        obj.act_start_time = Math.ceil(this.ruleForm.startTime[0].getTime() / 1000)
        obj.act_end_time = Math.ceil(this.ruleForm.startTime[1].getTime() / 1000)
        obj.act_end_time = obj.act_start_time == obj.act_end_time ? obj.act_end_time + 60 * 60 * 24 - 1 : obj.act_end_time
      }
      if (this.ruleForm.city_id?.length) {
        obj.city_id = this.ruleForm.city_id[1]
      }
      this.$axios.post(this.$api.gateway.activity.getList, obj).then(res => {
        if (res.code == 0) {
          this.dataList = res.result.list
          this.dataTotal = res.result.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getActivityStatus (data) {
      let nowTime = Math.ceil(new Date().getTime() / 1000)
      if (nowTime < data.start_time) return '未开始'
      if (nowTime >= data.start_time || nowTime <= data.end_time) return '进行中'
      if (nowTime > data.end_time) return '已完成'
    },
    deleteActivity (data) {
      this.$confirm('此操作将永久删除该活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return this.$axios.post(this.$api.gateway.activity.delActivity, {
          id: data.id
        })
      }).then(res => {
        if (res.code == 0) {
          this.$message.success('删除成功')
          this.getDataList()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => { });
    },
    detailsInfo (data, style) {
      if (style) {
        this.$router.push('/extension/gatewayActivity/lookActivity?look_id=' + data.id)
      } else {
        this.$router.push('/extension/gatewayActivity/editActivity?edit_id=' + data.id)
      }
    },
    upChange (data) {
      if (data.is_up) {
        data.is_up = 0
        // 展示
        this.$confirm('请确认是否要展示该活动！！！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          return this.$axios.post(this.$api.gateway.activity.isUp, {
            id: data.id,
            is_up: data.is_up
          })
        }).then(res => {
          if (res.code == 0) {
            this.$message.success('操作成功')
            data.is_up = 1
          } else {
            this.$message.error(res.msg)
          }
        }).catch();
      } else {
        data.is_up = 1
        this.nowEditData = data
        this.rejectDialogFlag = true
      }
    },
    // 处理驳回
    dealReject (style) {
      if (!style) {
        // 取消驳回
        this.rejectDialogFlag = false
        this.rejectResultTxt = ''
      } else {
        // 确定驳回
        if (this.rejectResultTxt === '') {
          this.$message.warning('请填写下架理由!!!')
          return
        }
        this.$axios.post(this.$api.gateway.activity.isUp, {
          id: this.nowEditData.id,
          is_up: 0,
          down_reason: this.rejectResultTxt
        }).then(res => {
          if (res.code === 0) {
            this.$message.success('下架成功')
            this.dealReject(0)
            this.getDataList()
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
}
</style>